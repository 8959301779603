import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import LiveChatIcon from '../../assets/icons/livechat-icon.svg'

const LiveCodingHeader = ({ eventDate, cta, ctaClassName }) => {
  return (
    <div className="c-navbar c-navbar-live-coding u-bg-black">
      <div className="c-navbar__content">
        <div className="u-flex u-items-center fixed-width">
          <Link to="/live-coding-server-side-app-authorization">
            <LiveChatIcon className="livechat-icon" />
          </Link>
          <div className="u-text-p6-bold u-ml-md tagline">
            Live coding session <br />
            with <Link to="/">Developer Program</Link>
          </div>
        </div>

        <div className="u-text-p3 u-flex-2 u-text-center dates-column">
          {eventDate}
        </div>
        <div className="u-text-right fixed-width cta-column">
          {cta.onClick ? (
            <a
              href={cta.onClick}
              onClick={cta.onClick}
              className={
                ctaClassName || 'c-btn v--livechat u-text-white u-text-p6-bold'
              }
            >
              {cta.label}
            </a>
          ) : (
            <a
              href={cta.url}
              target="_blank"
              rel="noopener noreferrer"
              className={
                ctaClassName || 'c-btn v--livechat u-text-white u-text-p6-bold'
              }
            >
              {cta.label}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

LiveCodingHeader.propTypes = {
  handleOpenModal: PropTypes.func,
  cta: PropTypes.object,
  eventDate: PropTypes.string,
  ctaClassName: PropTypes.string,
}

export default LiveCodingHeader
