import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { Main } from '../components'
import './styles/index.less'
import LiveCodingHeader from '../components/Header/LiveCodingHeader'
import Overlay from '../components/Txtlss/Overlay'

const Layout = ({ children, overlay, eventDate, headerCta, ctaClassName }) => {
  return (
    <div>
      <AnimatePresence>{overlay && <Overlay key="overlay" />}</AnimatePresence>
      <LiveCodingHeader
        eventDate={eventDate}
        cta={headerCta}
        ctaClassName={ctaClassName}
      />
      <Main>{children}</Main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  overlay: PropTypes.bool,
  eventDate: PropTypes.string,
  headerCta: PropTypes.object,
  shouldBlockScroll: PropTypes.bool,
  ctaClassName: PropTypes.string,
}

export default Layout
