import React, { useState } from 'react'
import useMedia from 'use-media'

import Layout from '../../layout/live-coding-layout'
import { SEO, Image } from '../../components'
import { decodeQueryString, canUseWindow } from '../../utils'

import './live-coding.scss'
import OgImage from '../../assets/images/livechat-live-coding-4-og.png'

const LiveCoding = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'
  const isWide = useMedia({ minWidth: 950 })

  const [overlay, setOverlay] = useState(initialState)

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      eventDate="Septmeber 16th, 2021"
      headerCta={{
        label: 'Join Discord',
        url: 'https://discord.gg/gRzwSaCxg4',
      }}
    >
      <SEO
        title="Live Coding Session with Developer Program"
        description="Take part in a online live coding session with the LiveChat Developer Program team. Put your design skills into practice and learn how to confidently implement your app's design with the LiveChat Design System."
        image={OgImage}
      />
      <section
        className="u-text-center u-Px-xl live-coding-bg"
        style={{ width: '100vw', height: 'calc(100vh - 90px)' }}
      >
        <Image
          type="privateAppsOverview2"
          alt="live-coding"
          className="u-mb-lg u-mx-auto"
          wrapperStyles={{ width: isWide ? '30%' : '80%' }}
        />

        <div className="">
          <h2 className="u-text-p3 u-mt-0 u-mb-sm">Thank you</h2>
          <h3 className="u-text-p5">See you at the live coding!</h3>
        </div>

        <div className="u-Mt-xs u-flex u-items-center u-justify-center u-flex-col sm:u-flex-row">
          <a
            href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210916T150000Z%2F20210916T160000Z&details=%3Cb%3EIt%E2%80%99s%20great%20to%20have%20you%20on%20board%21%3C%2Fb%3E%20%0AJoin%20us%20on%20Thursday%2C%20September%2016th%20for%20a%20live%20coding%20session%20demonstrating%20how%20to%20effectively%20build%20paid%20applications%20that%20are%20in%20line%20with%20the%20LiveChat%20look%20and%20feel.%3Cbr%3E%0AAfter%20the%20session%2C%20you%E2%80%99ll%20know%20how%20to...%0A%3Cul%3E%0A%3Cli%3ESpeed%20up%20app%20development%20with%20the%20ready-to-use%20React%20components.%3C%2Fli%3E%0A%3Cli%3EDeliver%20a%20good%20user%20experience%20in%20your%20application%20and%20avoid%20design%20anti-patterns.%3C%2Fli%3E%0A%3Cli%3EBuild%20applications%20that%20match%20the%20LiveChat%20look%20and%20feel%2C%20making%20the%20design%20review%20process%20of%20your%20app%20a%20breeze.%3C%2Fli%3E%0A%3C%2Ful%3E%0A%3Cb%3EWe%20look%20forward%20to%20coding%20together%21%20%3C%2Fb%3E%0ALink%20to%20the%20event%3A%20%3Ca%20href%3D%22https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dd792twx7Q0I%22%20%3Echeck%20out%20Youtube%3C%2Fa%3E%0ARead%20more%20information%20about%20the%20session%2C%20%3Ca%20href%3D%22https%3A%2F%2Fdevelopers.livechat.com%2Flive-coding-livechat-design-system-in-use%2F%3Futm_source%3Dcalendar%26utm_medium%3Dcalendar_invite%26utm_id%3Dlcs4%22%20title%3D%22Save%20Event%20in%20my%20Calendar%22%20%3Evisit%20the%20event%20website%3C%2Fa%3E&text=LiveChat%20Live%20Coding%20Session%3A%20Build%20apps%20with%20LiveChat%20Design%20System"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="c-btn">Add to Calendar</button>
          </a>

          <span className="u-m-md">and</span>
          <a
            href={process.env.GATSBY_CONSOLE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="c-btn">Join Developer Console</button>
          </a>
        </div>

        <div className="u-Mt-xl u-flex u-flex-col u-items-center">
          <h3 className="u-text-p5">See our previous sessions:</h3>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
            href="https://www.youtube.com/watch?v=ei8YG9mLdfI"
            target="_blank"
            rel="noopener noreferrer"
          >
            Server-side App Authorization
          </a>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
            href="https://www.youtube.com/watch?v=SjqCBgAaEbc&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Building LiveChat apps 101
          </a>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
            href="https://youtu.be/FDmLqEiCH60"
            target="_blank"
            rel="noopener noreferrer"
          >
            Authorizing web apps
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default LiveCoding
